import consumer from "./consumer"

consumer.subscriptions.create("RequestChannel", {
  connected() {
    // Called when the subscription is ready for use on the server
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(data) {
    const newContent = `
      <div class="main_package_buttons">
        <a data-turbolinks="false" href="/requests/${data.request.uuid}" class="btn btn-border-shadow btn-border-shadow--color3">
          <div class="small_font">
            <i class="fas fa-solid fa-bell"></i>
            Check this out
          </div>
          A new job just poppedup!
        </a>
      </div>
    `;
    $("#new_requests_content").append(newContent);  
  }
});